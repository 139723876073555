import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function workTemplate({ data }) {
  const post = data.markdownRemark
  const { url } = post.frontmatter
  console.log(data)
  return (
    <Layout>
      <SEO title={post.frontmatter.title} keywords={post.frontmatter.tags} />
      <div style={{ backgroundColor: post.frontmatter.bgColor }} className="text-white min-h-screen">
        <div className="relative mb-6 ">
          {post.frontmatter.video && (
            <div className="bg-black relative">
              <video
                controls={false}
                autoPlay
                muted
                loop
                className="w-full h-screen md:h-half opacity-75 block"
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  filter: post.frontmatter.screenshotImage ? 'blur(5px)' : null,
                }}
              >
                <source src={post.frontmatter.video} type="video/mp4" />
              </video>
              {post.frontmatter.screenshotImage && (
                <div className="absolute pin w-full flex justify-center items-center md:items-end md:-mb-12">
                  <Img
                    className="w-full  max-w-lg h-auto flex-1"
                    alt={`${post.frontmatter.title} screenshots`}
                    fluid={post.frontmatter.screenshotImage.childImageSharp.fluid}
                  />
                </div>
              )}
            </div>
          )}
          {post.frontmatter.heroImage && (
            <>
              <Img
                className={`work-header h-screen md:h-half opacity-75 ${
                  post.frontmatter.screenshotImage ? 'blur' : ''
                }`}
                fluid={post.frontmatter.heroImage.childImageSharp.fluid}
              />
              {post.frontmatter.screenshotImage && (
                <div className="absolute pin w-full flex justify-center items-center md:items-end md:-mb-12">
                  <Img
                    className="w-full  max-w-lg h-auto flex-1"
                    alt={`${post.frontmatter.title} screenshots`}
                    fluid={post.frontmatter.screenshotImage.childImageSharp.fluid}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={`mx-auto max-w-lg px-4 py-16 ${
            post.frontmatter.bgColor === '#45f132' ? 'text-black' : 'text-white'
          }`}
        >
          <h1 className="font-impact text-5xl">{post.frontmatter.title}</h1>
          <div className="post font-serif text-lg" dangerouslySetInnerHTML={{ __html: post.html }} />
          {url && (
            <p className="mt-12 text-center">
              <OutboundLink
                className={`font-impact  text-3xl  border-2 p-3 text-lg inline-block ${
                  post.frontmatter.bgColor === '#45f132' ? 'text-black border-black' : 'text-white border-white'
                }`}
                href={url}
              >
                Visit the site
              </OutboundLink>
            </p>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        date
        bgColor
        url
        video
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        screenshotImage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
